var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-bar"},[_c('ul',{staticClass:"filter-title"},[_c('li',[_c('a',{staticClass:"filter-a type-0",class:{ 'filter-active': _vm.chooseType === 0 },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.onClickType(0)}}},[_c('img',{staticClass:"filter-a-img",attrs:{"src":require('@/assets/images/logo-vert.jpg'),"alt":""}}),_c('em',{staticClass:"lg-block"},[_vm._v("全部")]),_vm._v(" ("+_vm._s(_vm.typeNum["0"] || 0)+") ")])]),_c('li',[_c('a',{staticClass:"filter-a type-1",class:{ 'filter-active': _vm.chooseType === 1 },style:({
          cursor: (_vm.typeNum['1'] || 0) === 0 ? 'not-allowed' : 'pointer'
        }),attrs:{"href":"javascript:void(0);","disabled":(_vm.typeNum['1'] || 0) === 0},on:{"click":function($event){return _vm.onClickType(1)}}},[_c('span',{staticClass:"iconfont icon-search_off filter-a-img"}),_c('em',{staticClass:"lg-block"},[_vm._v("查询不到")]),_vm._v(" ("+_vm._s(_vm.typeNum["1"] || 0)+") ")])]),_c('li',[_c('a',{staticClass:"filter-a type-2",class:{ 'filter-active': _vm.chooseType === 2 },style:({
          cursor: (_vm.typeNum['2'] || 0) === 0 ? 'not-allowed' : 'pointer'
        }),attrs:{"href":"javascript:void(0);","disabled":(_vm.typeNum['2'] || 0) === 0},on:{"click":function($event){return _vm.onClickType(2)}}},[_c('span',{staticClass:"iconfont icon-car filter-a-img"}),_c('em',{staticClass:"lg-block"},[_vm._v("运输途中")]),_vm._v(" ("+_vm._s(_vm.typeNum["2"] || 0)+") ")])]),_c('li',[_c('a',{staticClass:"filter-a type-4",class:{ 'filter-active': _vm.chooseType === 3 },style:({
          cursor: (_vm.typeNum['3'] || 0) === 0 ? 'not-allowed' : 'pointer'
        }),attrs:{"href":"javascript:void(0);","disabled":(_vm.typeNum['3'] || 0) === 0},on:{"click":function($event){return _vm.onClickType(3)}}},[_c('span',{staticClass:"iconfont icon-cry filter-a-img"}),_c('em',{staticClass:"lg-block"},[_vm._v("投递失败")]),_vm._v(" ("+_vm._s(_vm.typeNum["3"] || 0)+") ")])]),_c('li',[_c('a',{staticClass:"filter-a type-5",class:{ 'filter-active': _vm.chooseType === 4 },style:({
          cursor: (_vm.typeNum['4'] || 0) === 0 ? 'not-allowed' : 'pointer'
        }),attrs:{"href":"javascript:void(0);","disabled":(_vm.typeNum['4'] || 0) === 0},on:{"click":function($event){return _vm.onClickType(4)}}},[_c('span',{staticClass:"iconfont icon-hook filter-a-img"}),_c('em',{staticClass:"lg-block"},[_vm._v("签收成功")]),_vm._v(" ("+_vm._s(_vm.typeNum["4"] || 0)+") ")])]),_c('li',[_c('a',{staticClass:"filter-a type-6",class:{ 'filter-active': _vm.chooseType === 5 },style:({
          cursor: (_vm.typeNum['5'] || 0) === 0 ? 'not-allowed' : 'pointer'
        }),attrs:{"href":"javascript:void(0);","disabled":(_vm.typeNum['5'] || 0) === 0},on:{"click":function($event){return _vm.onClickType(5)}}},[_c('span',{staticClass:"iconfont icon-icon_caveat filter-a-img"}),_c('em',{staticClass:"lg-block"},[_vm._v("可能异常")]),_vm._v(" ("+_vm._s(_vm.typeNum["5"] || 0)+") ")])])]),_c('div',{staticStyle:{"width":"200px","margin-right":"40px"}},[_c('span',{staticClass:"filter-a floatRight"},[_c('Icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copyhttplist",value:(_vm.httplist),expression:"httplist",arg:"copyhttplist"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"filter-a type-0 copyHand",attrs:{"type":"ios-copy","size":"30","trigger":"hover","title":"点击复制"},on:{"click":_vm.clickBtn}}),_vm._v("复制")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }