import request from "@/libs/request";

export const searchAll = data => {
  return request({
    url: "/route/findwaybill",
    data,
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  });
};
export const getListDetail = waybillNo => {
  return request({
    url: "/route/findwaybillDetails",
    method: "get",
    params: { waybillNo },
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  });
};
