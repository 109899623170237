<template>
  <ul class="filter-line">
    <!--<template v-if="isEmpty">-->
    <contentLi
      v-for="item in filterData &&
      Array.isArray(filterData) &&
      filterData.length > 0
        ? filterData
        : listData"
      :key="item.waybillNo"
      :item="item"
    ></contentLi>
    <!--</template>-->
    <!--<li class="empty-box" v-else>-->
    <!--<div class="iconfont icon-bianzu" style="font-size: 40px;"></div>-->
    <!--暂无查询结果-->
    <!--</li>-->
  </ul>
</template>

<script>
import { data } from "@/libs/common";
import contentLi from "./content";
export default {
  name: "list",
  props: ["listData", "chooseType"],
  components: {
    contentLi
  },
  data() {
    return {
      filterData: []
    };
  },
  computed: {
    isEmpty() {
      return (
        (this.chooseType === 0 && this.listData.length > 0) ||
        (this.chooseType !== 0 && this.filterData.length > 0)
      );
    }
  },
  watch: {
    chooseType: {
      immediate: true,
      handler(newVal) {
        this.onFilter(newVal);
      }
    }
  },
  methods: {
    onFilter(type = 0) {
      this.filterData = this.listData.filter(
        item => type === 0 || item.status === type
      );
      window.localStorage.setItem(
        "myCopyData",
        JSON.stringify(this.filterData)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-line {
  .empty-box {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
