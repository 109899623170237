<template>
  <div class="search-bar">
    <ul class="filter-title">
      <!--1查询不到，2运输途中，3投递失败，4签收成功，5可能异常-->
      <li>
        <a
          class="filter-a type-0"
          :class="{ 'filter-active': chooseType === 0 }"
          href="javascript:void(0);"
          @click="onClickType(0)"
        >
          <img
            class="filter-a-img"
            :src="require('@/assets/images/logo-vert.jpg')"
            alt=""
          />
          <em class="lg-block">全部</em>
          ({{ typeNum["0"] || 0 }})
        </a>
      </li>
      <li>
        <a
          class="filter-a type-1"
          :class="{ 'filter-active': chooseType === 1 }"
          href="javascript:void(0);"
          :style="{
            cursor: (typeNum['1'] || 0) === 0 ? 'not-allowed' : 'pointer'
          }"
          @click="onClickType(1)"
          :disabled="(typeNum['1'] || 0) === 0"
        >
          <span class="iconfont icon-search_off filter-a-img"></span>
          <em class="lg-block">查询不到</em>
          ({{ typeNum["1"] || 0 }})
        </a>
      </li>
      <li>
        <a
          class="filter-a type-2"
          :class="{ 'filter-active': chooseType === 2 }"
          :style="{
            cursor: (typeNum['2'] || 0) === 0 ? 'not-allowed' : 'pointer'
          }"
          href="javascript:void(0);"
          @click="onClickType(2)"
          :disabled="(typeNum['2'] || 0) === 0"
        >
          <span class="iconfont icon-car filter-a-img"></span>
          <em class="lg-block">运输途中</em>
          ({{ typeNum["2"] || 0 }})
        </a>
      </li>
      <!--<li>-->
      <!--<a-->
      <!--class="filter-a type-3"-->
      <!--:class="{ 'filter-active': chooseType === 3 }"-->
      <!--href="javascript:void(0);"-->
      <!--@click="chooseType = 3"-->
      <!--&gt;-->
      <!--<span class="iconfont icon-flag filter-a-img"></span>-->
      <!--<em class="lg-block">到达代取</em>-->
      <!--({{ searchData.pending || 0 }})-->
      <!--</a>-->
      <!--</li>-->
      <li>
        <a
          class="filter-a type-4"
          :class="{ 'filter-active': chooseType === 3 }"
          :style="{
            cursor: (typeNum['3'] || 0) === 0 ? 'not-allowed' : 'pointer'
          }"
          href="javascript:void(0);"
          @click="onClickType(3)"
          :disabled="(typeNum['3'] || 0) === 0"
        >
          <span class="iconfont icon-cry filter-a-img"></span>
          <em class="lg-block">投递失败</em>
          ({{ typeNum["3"] || 0 }})
        </a>
      </li>
      <li>
        <a
          class="filter-a type-5"
          :class="{ 'filter-active': chooseType === 4 }"
          :style="{
            cursor: (typeNum['4'] || 0) === 0 ? 'not-allowed' : 'pointer'
          }"
          href="javascript:void(0);"
          @click="onClickType(4)"
          :disabled="(typeNum['4'] || 0) === 0"
        >
          <span class="iconfont icon-hook filter-a-img"></span>
          <em class="lg-block">签收成功</em>
          ({{ typeNum["4"] || 0 }})
        </a>
      </li>
      <li>
        <a
          class="filter-a type-6"
          :class="{ 'filter-active': chooseType === 5 }"
          :style="{
            cursor: (typeNum['5'] || 0) === 0 ? 'not-allowed' : 'pointer'
          }"
          href="javascript:void(0);"
          @click="onClickType(5)"
          :disabled="(typeNum['5'] || 0) === 0"
        >
          <span class="iconfont icon-icon_caveat filter-a-img"></span>
          <em class="lg-block">可能异常</em>
          ({{ typeNum["5"] || 0 }})
        </a>
      </li>
      <!--<li><a class="filter-a" href="#"><span class="iconfont">&#xe66e</span><em>运输过久</em>({{ searchData.timeout || 0 }})</a></li>-->
    </ul>
    <div style="width: 200px;margin-right: 40px">
      <span class="filter-a floatRight">
        <Icon
          class="filter-a type-0 copyHand"
          type="ios-copy"
          size="30"
          @click="clickBtn"
          v-clipboard:copyhttplist="httplist"
          v-clipboard:success="onCopy"
          trigger="hover"
          title="点击复制"
        />复制</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  props: ["typeNum"],
  data() {
    return {
      chooseType: 0,
      filterData: [
        {
          type: 0,
          title: "全部"
        }
      ],
      message: "",
      httplist: "",
      copyData: {},
      localData: [],
      showType: [
        "全部",
        "查询不到",
        "运输途中",
        "投递失败",
        "签收成功",
        "可能异常"
      ]
    };
  },
  methods: {
    onClickType(type) {
      this.chooseType = type;
      this.$emit("checkType", type);
    },
    onCopy(e) {
      this.$Message.config({
        top: 80,
        duration: 3
      });
      this.$Message.success("复制成功!");
      // this.$notify({
      //   message: "复制成功",
      //   type: "success"
      // });
    },
    copymessage: function(e) {
      this.httplist = "bbb";
    },
    copyhttplist: function(e) {
      this.httplist = "rrr";
    },
    clickBtn() {
      if (window.localStorage.getItem("myCopyData") !== "") {
        this.localData = JSON.parse(window.localStorage.getItem("myCopyData"));
        this.httplist = "";
        let showDate = "";
        this.localData.forEach(i => {
          if (i.occurTime !== 0) {
            showDate = new Date(i.occurTime).pattern("yyyy-MM-dd HH:mm:ss");
          } else {
            showDate = "";
          }
          this.httplist =
            this.httplist +
            `${i.searchNo || ""}   \t${i.originCountry ||
              ""}   \t ${i.targetCountry ||
              ""}   \t ${showDate} ${i.occurAddr || ""}  \t ${i.routeContent ||
              ""} \t ${this.showType[i.status] || ""}  \n`;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.floatRight {
  float: right;
}
.copyHand {
  cursor: pointer;
}
.filter-title {
  display: flex;
  flex: 1 1 auto;
  li {
    .filter-a {
      display: flex;
      max-width: 160px;
      height: 62px;
      padding: 18px 10px;
      align-items: center;
      flex-wrap: nowrap;
      border-width: 0 0 2px;
      border-bottom: 2px solid transparent;
      &-img {
        width: 30px;
        height: 25px;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        border-bottom: 2px solid #e2e2e2;
      }
    }
    .filter-active {
      border-bottom: 2px solid #003a9b !important;
    }
  }
}
@media (max-width: 767px) {
  .filter-title {
    flex-wrap: wrap;
    li {
      .filter-a {
        &-img {
          width: 25px;
          height: 25px;
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 1040px) {
  .lg-block {
    display: none;
  }
}
.search-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
