<template>
  <li class="li-list">
    <div class="line-item"
        @click="onGetListDetail(item)">
      <div class="track-header">
        <a
          class="iconfont track-header-icon"
          :class="[item.icon, `type-${item.status + 1}`]"
        ></a>
        <div class="text-content">
          <p class="text-uppercase">{{ item.searchNo }}</p>
          <p class="text-capitalize item-title">{{ item.title }}</p>
        </div>
      </div>
      <div class="track-content">
        <div class="choose-track">
          <span class="track-content-text">{{ item.originCountry }}</span>
          <Icon
            type="md-arrow-forward"
            style="padding: 0 10px 0 10px;"
            size="22"
          />
          <span class="track-content-text">{{ item.targetCountry }}</span>
        </div>
      </div>
      <div
        class="track-last"
        v-if="item.waybillNo != null"
      >
        <p class="track-contain">
          {{ item.occurTime | filterTime }}
          <span class="content-text"
            >{{ item.occurAddr
            }}{{
              item.occurAddr && item.routeContent
                ? " , " + item.routeContent
                : item.routeContent
            }}</span
          >
        </p>
        <a class="track-last-btn">
          <span v-if="!isShowContent" class="iconfont icon-down"></span>
          <span v-else class="iconfont icon-up"></span>
        </a>
      </div>
      <!--<div class="track-error">-->
      <!--<p>你的单号我们暂时无法识别。</p>-->
      <!--</div>-->
    </div>
    <!--<div class="li-content" >-->
    <div class="li-content" v-if="isShowContent">
      <Timeline>
        <template v-for="(i, iIndex) in routeList">
          <TimelineItem
            color="green"
            class="content-text-first"
            style="font-size: 14px"
            v-if="iIndex === 0"
            :key="iIndex"
          >
            <p class="time-line-track-content-text">
              <span class="content-text-time">{{
                i.occurTime | filterTime
              }}</span>
              <span class="content-text-content"
                >{{ i.occurAddr
                }}{{
                  i.occurAddr && i.routeContent
                    ? " , " + i.routeContent
                    : i.routeContent
                }}</span
              >
            </p>
          </TimelineItem>
          <TimelineItem
            class="content-text"
            style="font-size: 14px"
            v-else
            :key="iIndex"
          >
            <p class="time-line-track-content-text">
              <span class="content-text-time">{{
                i.occurTime | filterTime
              }}</span>
              <span class="content-text-content"
                >{{ i.occurAddr
                }}{{
                  i.occurAddr && i.routeContent
                    ? " , " + i.routeContent
                    : i.routeContent
                }}</span
              >
            </p>
          </TimelineItem>
        </template>
      </Timeline>
    </div>
  </li>
</template>

<script>
import { Icon, Timeline, TimelineItem } from "iview";
import { getListDetail } from "@/api/home";
export default {
  name: "contentLi",
  props: ["item"],
  components: {
    Icon,
    Timeline,
    TimelineItem
  },
  data() {
    return {
      isShowContent: false,
      routeList: []
    };
  },
  filters: {
    filterTime(time) {
      return new Date(time).pattern("yyyy-MM-dd HH:mm:ss");
    }
  },
  methods: {
    onGetListDetail({ waybillNo }) {
      if (this.routeList.length) {
        this.isShowContent = !this.isShowContent;
        return;
      }
      getListDetail(waybillNo).then(res => {
        this.routeList = res;
        this.isShowContent = !this.isShowContent;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$time-line-track-content-text-hover-color: #f8f8f9;
.content-text-first {
  padding-bottom: 0 !important;
  .time-line-track-content-text {
    font-weight: bold;
    line-height: 30px;
    margin-top: -7px;
    padding: 2px 0 2px 12px;
    &:hover {
      background: $time-line-track-content-text-hover-color;
    }
    .content-text-content {
      padding-left: 20px;
    }
    .content-text-time {
      display: inline-block;
      width: 166px;
      letter-spacing: -0.8px;
    }
  }
}
.content-text {
  font-size: 14px !important;
  padding-bottom: 0 !important;
  .time-line-track-content-text {
    color: #757575;
    line-height: 30px;
    margin-top: -7px;
    padding: 2px 0 2px 12px;
    &:hover {
      background: $time-line-track-content-text-hover-color;
    }
    .content-text-content {
      padding-left: 20px;
    }
    .content-text-time {
      display: inline-block;
      width: 166px;
    }
  }
}
.li-list {
  background: #fff;
  border: 1px solid #e0e0e0;
  margin-bottom: 5px;
}
.line-item {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  cursor: pointer;
  .track-header {
    width: 25%;
    display: flex;
    padding: 10px;
    &-icon {
      font-size: 30px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
    }
    .text-content {
      .item-title {
        font-size: 12px;
      }
    }
  }
  .track-content {
    width: 35%;
    padding: 10px;
    .choose-track {
      max-width: 320px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .track-content-icon {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        display: none;
      }
      .track-content-text {
        display: inline-block;
      }
    }
  }
  .track-last {
    display: flex;
    padding: 10px;
    justify-content: left;
    align-items: center;
    flex: 1;
    .track-contain {
      flex: 1;
      line-height: 20px;
    }
    &-btn {
      flex: 0 0 auto;
      padding: 8px;
    }
  }
  .track-error {
    display: flex;
    padding: 10px;
    align-items: center;
    & > p {
      line-height: 20px;
    }
  }
}
.li-content {
  padding: 15px 15px 0 15px;
  .ivu-timeline-item-head-green {
    background-color: #19be6b;
  }
}

@media (max-width: 770px) {
  .line-item {
    flex-wrap: wrap;
    .track-header {
      width: 100% !important;
      border-bottom: 1px solid #e0e0e0;
    }
    .track-content {
      width: 100% !important;
      border-bottom: 1px solid #e0e0e0;
      .choose-track {
        max-width: 100% !important;
        background-color: inherit !important;
        padding: 0;
        justify-content: left !important;
        .track-content-icon {
          display: inline-flex !important;
        }
        .track-content-text {
          line-height: 40px;
          display: inline-block !important;
        }
      }
    }
    .track-error {
    }
  }
}
</style>
<style lang="scss">
.ivu-timeline-item-head-green {
  background-color: #19be6b;
}
.ivu-timeline-item-content {
  font-size: 14px !important;
  padding: 1px 1px 0 24px !important;
}
</style>
