let iconfig = {
  /***
   * 请求路由
   * */
  // apiUrl: "http://192.168.180.85:80/route-soa/"
  apiUrl: process.env.VUE_APP_BASE_URL,
  /**
   * 首页搜索框输入限制
   * **/
  indexSearchNum: 500
};
export default iconfig;
