<template>
  <main class="app-container">
    <main class="search-main">
      <article class="track-content">
        <div class="filter-box">
          <Type
            ref="showType"
            :typeNum="typeNumber"
            @checkType="type => (chooseType = type)"
          ></Type>
          <!--<div class="filter-list">-->
          <!--<button class="btn">刷新</button>-->
          <!--<button class="btn">删除</button>-->
          <!--</div>-->
        </div>
        <div class="search-box">
          <List
            ref="showList"
            :listData="listData"
            :chooseType="chooseType"
          ></List>
        </div>
      </article>
      <aside class="track-input">
        <div class="input-box">
          <codemirror
            ref="cmEditor"
            v-model="curCode"
            :placeholder="'每行输入一个单号，最多只允许提交40个单号。'"
            :options="cmOptions"
            class="six-input"
          ></codemirror>
        </div>
        <el-row :gutter="16" style="margin-top:10px;">
          <el-col :span="12">
            <el-button
              type="primary"
              @click="onSearchData"
            >
              搜索
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button
              type="warning"
              @click="curCode = ''"
            >
              清除
            </el-button>
          </el-col>
        </el-row>
      </aside>
    </main>
  </main>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "@/assets/css/six-track/code.css";
import "codemirror/theme/eclipse.css"; // 这里引入的是主题样式，根据设置的theme的主题引入，一定要引入！！
require("codemirror/mode/javascript/javascript");
import Type from "./type";
import List from "./list";
import { searchAll } from "@/api/home";
import { data } from "@/libs/common";
import config from "@/config/index";
export default {
  name: "Home",
  components: {
    Type,
    List,
    codemirror,
  },
  data() {
    return {
      chooseType: 0,
      curCode: "",
      cmOptions: {
        tabSize: 4,
        mode: "text",
        theme: "eclipse",
        lineNumbers: true,
        line: true,
        smartIndent: false
      },
      listData: [],
      typeNumber: {}
    };
  },
  created() {
    this.doQuery();
  },
  methods: {
    doQuery() {
      const {
        query: { nums = "" }
      } = this.$route;
      if (nums.length > 0) {
        let searchNum = nums.split(",");
        searchNum = searchNum.filter(item => {
          return item.length > 0;
        });
        this.curCode = searchNum.join("\n");
        searchAll(searchNum).then(res => {
          this.listData = res.map(item => ({
            ...item,
            ...data.find(i => item.status === i.status)
          }));
          window.localStorage.setItem(
            "myCopyData",
            JSON.stringify(this.listData)
          );
          this.typeNumber = this.onFilterTypeNumber(res);
          //还原子组件的状态
          this.$refs.showList.filterData = [];
          this.$refs.showType.chooseType = 0;
        });
      }
    },
    onSearchData() {
      const searchArr = this.curCode.split("\n");
      let limitNum = config.indexSearchNum || 500;
      if (searchArr.length > limitNum) {
        this.$Modal.confirm({
          title: "温馨提示",
          width: 580,
          content: `<p>最多只允许提交${limitNum}个单号,您的输入已经超出限制！</p><p>系统将提交前${limitNum}个单号，剩余单号将被忽略，是否继续提交？</p>`,
          onOk: () => {
            let newArr = searchArr.slice(0, limitNum);
            this.checkOrder(newArr);
          }
        });
        return;
      }
      this.checkOrder(searchArr);
    },
    checkOrder(searchArr) {
      const isError = searchArr.some((item, index) => {
        const len = item.length < 6 && item.length !== 0;
        if (len)
          this.$message.error(`第${index + 1}行单号有误，请输入正确的单号`);
        return len;
      });
      if (!isError) {
        let oldAr = this.$route.query.nums;
        let newAr = searchArr.join(",");
        if (oldAr === newAr) {
          return;
        }
        this.$route.query.nums = searchArr.join(",");
        this.doQuery();
      }
    },
    onFilterTypeNumber(allItem) {
      let nameContainer = {}; // 针对键name进行归类的容器
      allItem.map(item => {
        nameContainer[item.status] = nameContainer[item.status] || [];
        nameContainer[item.status] = ~~nameContainer[item.status] + 1;
      });
      nameContainer[0] = allItem.length;
      return nameContainer;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/mixin";
.search-main {
  height: calc(100vh - 76px);
  background: #f4f4f4;
  padding: 0 20px;
  position: relative;
  .track-content {
    width: calc(100vw - 280px);
    .filter-box {
      display: flex;
      align-items: center;
    }
    .search-box {
      height: calc(100vh - 160px);
      overflow-x: auto;
      @include scroll-style();
    }
  }
  .track-input {
    background: #ffffff !important;
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    padding: 18px 10px 10px 10px;
    height: 100%;
    -webkit-box-shadow: -1px 0 4px 0 rgb(0 0 0 / 10%);
    box-shadow: -1px 0 4px 0 rgb(0 0 0 / 10%);
    .input-box {
      border: 2px solid #ccc;
    }
    .btn {
      margin-top: 15px;
      padding: 3px;
      font-size: 18px;
      background-color: #ff8c00;
      border-color: #ff8c00;
    }
  }
}
@media (max-width: 1140px) {
  .search-box {
    height: calc(100vh - 225px) !important;
  }
}
@media (max-width: 1265px) {
  .track-input {
    display: none;
  }
  .track-content {
    width: 100% !important;
  }
}
</style>
